<template>
  <div>
    <GenericForm
      title="usuário"
      previousRoute="usuario"
      editRoute="usuarioEditar"
      :model="model"
      :route="usuarioRoute"
      :editing="editing"
      @updateModel="updateModel"
      idModel="id_usuario"
      :permissionsToWrite="['rw_usuario']"
      :permissionsToEdit="['rw_usuario']"
      :permissionsToDelete="['rw_usuario']"
      >
      <template v-slot="{ validateState, isBusy }">
        <b-row>
            <b-col>
              <b-form-group label="Nome*" label-for="nome">
                <b-form-input
                  id="nome"
                  v-model="model.nome"
                  autocomplete="off"
                  class="invision-input"
                  name="nome"
                  :disabled="isBusy"
                  v-validate="{ required: true }"
                  :state="validateState('nome')"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Cargo*" label-for="grupousuario">
                <b-input-group>
                  <GenericSelect
                    name="grupo"
                    labelKey="nome"
                    ref="grupo"
                    v-model="model.id_grupo_usuario"
                    :route="grupoUsuarioRoute"
                    :disabled="isBusy"
                    v-validate="'required'"
                    :state="validateState('grupo')"
                  ></GenericSelect>
                </b-input-group>
              </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
              <b-form-group label="Registro*" label-for="registro">
                <b-form-input
                  id="registro"
                  v-model="model.registro"
                  autocomplete="off"
                  class="invision-input"
                  name="registro"
                  :disabled="isBusy"
                  v-validate="{ required: true }"
                  :state="validateState('registro')"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <InputSenha
                name="senha"
                v-model="model.senha"
                v-validate="{ required: !editing }"
                :state="validateState('senha')"
              />
            </b-col>
        </b-row>
        <b-row>
          <b-col sm="6" md="6" lg="6" xl="6">
            <b-form-group :label="corenLabel" label-for="coren">
              <b-form-input
                id="coren"
                v-model="model.coren"
                autocomplete="off"
                class="invision-input"
                name="coren"
                type="text"
                :disabled="isBusy"
                v-validate="{ required: corenIsRequired }"
                :state="validateState('coren')"
              ></b-form-input>
            </b-form-group>
          </b-col>
            <b-col sm="6" md="6" lg="6" xl="6">
              <b-form-group label="Permitir acesso as Unidades:" label-for="unidade">
                <GenericMultipleSelect
                  ref="unidade"
                  name="unidade"
                  labelKey="nome"
                  v-model="unidades"
                  route="unidade"
                ></GenericMultipleSelect>
              </b-form-group>
            </b-col>
        </b-row>
        <b-row>
          <b-input-group>
            <b-col sm="12" md="12" lg="12" xl="12">
              <b-form-group label="Permitir acesso aos Setores:" label-for="area">
                <GenericMultipleSelect
                  ref="area"
                  name="area"
                  labelKey="descricao"
                  v-model="areas"
                  route="area"
                ></GenericMultipleSelect>
              </b-form-group>
            </b-col>
          </b-input-group>
        </b-row>
        <b-row>
          <b-col sm="6" md="6" lg="2" xl="2">
            <b-form-group label="Status" label-for="ativo">
              <b-form-checkbox
                id="ativo"
                v-model="model.ativo"
                name="check-button"
                :disabled="isBusy"
                switch
              >{{ model.ativo == true ? 'Ativo' : 'Inativo' }}</b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col sm="6" md="6" lg="4" xl="4">
            <b-form-group label="Redefinir senha no próximo acesso" label-for="redefinicao">
              <b-form-checkbox
                id="redefinicao"
                v-model="model.redefinicao"
                name="check-button"
                :disabled="isBusy"
                switch
              >{{ model.redefinicao == true ? 'Sim' : 'Não' }}</b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col sm="6" md="6" lg="2" xl="2">
            <b-form-group label="Temporário" label-for="temporario">
              <b-form-checkbox
                id="temporario"
                v-model="model.temporario"
                :input="handleChangeTemporario"
                name="check-button"
                :disabled="isBusy"
                switch
              >{{ model.temporario == true ? 'Sim' : 'Não' }}</b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col sm="6" md="6" lg="4" xl="4" v-if="model.temporario">
            <b-form-group label="Data limite de acesso*" label-for="dataLimite">
              <b-form-datepicker
                id="dataLimite"
                v-model="model.dataLimite"
                class="mb-2"
                v-validate="{ required: true }"
                :state="validateState('dataLimite')"
                label-no-date-selected="Selecione a data limite de acesso"
                locale="pt-br"
                :reset-button="Boolean(model.dataLimite)"
                label-reset-button="Limpar"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </GenericForm>

    <b-modal
      size="lg"
      id="modalGrupoUsuario"
      ref="modalGrupoUsuario"
      title="Gerenciar cargos"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >
      <GrupoUsuario
        ref="cadGrupos"
        @ok="fecharModalGrupoUsuario"
      ></GrupoUsuario>
    </b-modal>
  </div>
</template>

<script>
import GenericForm from '@/components/Form/GenericForm';
import GenericSelect from '@/components/Form/GenericSelect';
import GenericMultipleSelect from '@/components/Form/GenericMultipleSelect';
import InputSenha from '@/components/Form/InputSenha';
import GrupoUsuario from '@/views/GrupoUsuario';
import loginService from '@/services/login';

export default {
  components: {
    GenericForm,
    GenericSelect,
    GrupoUsuario,
    GenericMultipleSelect,
    InputSenha,
  },

  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      model: {
        id_usuario: this.$route.params.id || -1,
        nome: '',
        registro: '',
        senha: '',
        ativo: true,
        id_grupo_usuario: null,
        redefinicao: false,
        temporario: false,
        dataLimite: '',
        coren: '',
        idsAreas: [],
        idsUnidades: [],
      },
      areas: [],
      unidades: [],
      usuarioRoute: 'usuario',
      grupoUsuarioRoute: 'grupoUsuario',
    };
  },

  computed: {
    corenIsRequired() {
      if (!this.model.id_grupo_usuario || !this.$refs.grupo.apiResponse.length) return true;
      const grupo = this.$refs.grupo.apiResponse.find(
        (item) => item.id_grupo_usuario === this.model.id_grupo_usuario,
      );

      if (!grupo) return true;

      return grupo.coren_required;
    },
    corenLabel() {
      return this.corenIsRequired ? 'Coren*' : 'Coren';
    },
  },

  methods: {
    updateModel(model) {
      this.model = { ...model, senha: '' };
      this.model.redefinicao = this.editing
        ? !(this.$route.params.id === loginService.getUser().id_usuario)
        : true;
      this.areas = this.model.areas.map((area) => ({
        label: area.descricao,
        value: area.id_area,
      }));
      this.unidades = this.model.unidades.map((unidade) => ({
        label: unidade.nome,
        value: unidade.id_unidade,
      }));
    },

    updateSelectedValue(selection) {
      this.model[selection.key] = selection.value;
    },

    fecharModalGrupoUsuario() {
      this.$refs.modalGrupoUsuario.hide();
    },

    handleChangeTemporario() {
      this.model.dataLimite = '';
    },
  },

  watch: {
    areas: {
      immediate: true,
      handler(v) {
        this.model.idsAreas = v.map((area) => area.value);
      },
    },
    unidades: {
      immediate: true,
      handler(v) {
        this.model.idsUnidades = v.map((unidade) => unidade.value);
      },
    },
  },
};
</script>

<style>
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px !important;
  }
}
</style>
