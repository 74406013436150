<template>
  <b-form-group :label="label" :label-align="labelAlign" :label-for="id">
    <b-input-group>
      <b-form-input
        :id="id"
        :name="name"
        class="border-right-0"
        :type="typeInput"
        :value="value"
        @input="v => $emit('input', v)"
        :state="state"
        autocomplete="off"
        required
        @focus="$emit('focus')"
        @blur="$emit('blur')"
      ></b-form-input>
      <b-input-group-append>
        <button
          type="button"
          class="inlineBtn"
          @click="changeType"
        >
          <img :src="require(typeInput === 'password'
            ? '../../assets/img/closedEye.svg'
            : '../../assets/img/openedEye.svg')" />
        </button>
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Senha*',
    },
    id: {
      type: String,
      default: 'userPassword',
    },
    name: {
      type: String,
    },
    value: {
      type: String,
      required: true,
    },
    state: {
      type: Boolean,
      default: null,
    },
    labelAlign: {
      type: String,
      default: 'left',
    },
  },
  data() {
    return {
      typeInput: 'password',
    };
  },
  methods: {
    changeType() {
      this.typeInput = this.typeInput === 'password' ? 'text' : 'password';
    },
  },
};
</script>

<style scoped>
.inlineBtn {
  border: 1px solid #ced4da;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
